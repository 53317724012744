<template>
  <!-- //!#arts: palette -->
  <!-- palette default: bold -->
  <svg
    v-if="props.tag === 'arts' && !props.weight"
    xmlns="http://www.w3.org/2000/svg"
    :class="props.size === 5 ? `w-5 h-5` : 'w-8 h-8'"
    fill="#00293b"
    viewBox="0 0 256 256"
  >
    <rect width="256" height="256" fill="none"></rect>
    <path
      d="M221.61367,149.35463a96.28545,96.28545,0,0,0,2.38287-22.17837c-.44383-52.82934-44.1551-95.70346-96.98364-95.17129a96.01642,96.01642,0,0,0-30.989,186.54089A24.002,24.002,0,0,0,128,195.90236V192a24,24,0,0,1,24-24h46.20931A23.99131,23.99131,0,0,0,221.61367,149.35463Z"
      fill="none"
      stroke="#00293b"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="16"
    ></path>
    <circle cx="128" cy="76" r="12"></circle>
    <circle cx="82.96668" cy="102" r="12"></circle>
    <circle cx="82.96668" cy="154" r="12"></circle>
    <circle cx="173.03332" cy="102" r="12"></circle>
  </svg>

  <!-- palette regular -->
  <svg
    v-else-if="props.tag === 'arts'"
    xmlns="http://www.w3.org/2000/svg"
    :class="props.size === 5 ? `w-5 h-5` : 'w-8 h-8'"
    fill="#00293b"
    viewBox="0 0 256 256"
  >
    <rect width="256" height="256" fill="none"></rect>
    <path
      d="M221.61367,149.35463a96.28545,96.28545,0,0,0,2.38287-22.17837c-.44383-52.82934-44.1551-95.70346-96.98364-95.17129a96.01642,96.01642,0,0,0-30.989,186.54089A24.002,24.002,0,0,0,128,195.90236V192a24,24,0,0,1,24-24h46.20931A23.99131,23.99131,0,0,0,221.61367,149.35463Z"
      fill="none"
      stroke="#00293b"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="16"
    ></path>
    <circle cx="128" cy="76" r="12"></circle>
    <circle cx="82.96668" cy="102" r="12"></circle>
    <circle cx="82.96668" cy="154" r="12"></circle>
    <circle cx="173.03332" cy="102" r="12"></circle>
  </svg>

  <!-- //!#business: briefcase -->
  <!-- briefcase default: fill -->
  <svg
    v-else-if="props.tag === 'business' && !props.weight"
    xmlns="http://www.w3.org/2000/svg"
    :class="props.size === 5 ? `w-5 h-5` : 'w-6 h-6'"
    fill="#00293b"
    viewBox="0 0 256 256"
  >
    <rect width="256" height="256" fill="none"></rect>
    <path
      d="M216.00781,64H176V56a24.0275,24.0275,0,0,0-24-24H104A24.0275,24.0275,0,0,0,80,56v8H40.00781a16.01833,16.01833,0,0,0-16,16V208a16.01833,16.01833,0,0,0,16,16h176a16.01833,16.01833,0,0,0,16-16V80A16.01833,16.01833,0,0,0,216.00781,64ZM96,56a8.00917,8.00917,0,0,1,8-8h48a8.00917,8.00917,0,0,1,8,8v8H96ZM216.00781,80l.00318,41.606A184.00072,184.00072,0,0,1,128,144a184.00068,184.00068,0,0,1-87.99219-22.38428V80ZM108,120a8.00039,8.00039,0,0,1,8-8h24a8,8,0,0,1,0,16H116A8.00039,8.00039,0,0,1,108,120Z"
    ></path>
  </svg>

  <!-- briefcase regular -->
  <svg
    v-else-if="props.tag === 'business'"
    xmlns="http://www.w3.org/2000/svg"
    :class="props.size === 5 ? `w-5 h-5` : 'w-6 h-6'"
    fill="#00293b"
    viewBox="0 0 256 256"
  >
    <rect width="256" height="256" fill="none"></rect>
    <path
      d="M216.00781,64H176V56a24.0275,24.0275,0,0,0-24-24H104A24.0275,24.0275,0,0,0,80,56v8H40.00781a16.01833,16.01833,0,0,0-16,16V208a16.01833,16.01833,0,0,0,16,16h176a16.01833,16.01833,0,0,0,16-16V80A16.01833,16.01833,0,0,0,216.00781,64ZM96,56a8.00917,8.00917,0,0,1,8-8h48a8.00917,8.00917,0,0,1,8,8v8H96ZM216.00781,80l.00318,41.606A184.00072,184.00072,0,0,1,128,144a184.00068,184.00068,0,0,1-87.99219-22.38428V80ZM108,120a8.00039,8.00039,0,0,1,8-8h24a8,8,0,0,1,0,16H116A8.00039,8.00039,0,0,1,108,120Z"
    ></path>
  </svg>

  <!-- //!#computing: code -->
  <!-- code default: bold -->
  <svg
    v-else-if="props.tag === 'computing' && !props.weight"
    xmlns="http://www.w3.org/2000/svg"
    :class="props.size === 5 ? `w-5 h-5` : 'w-8 h-8'"
    fill="#00293b"
    viewBox="0 0 256 256"
  >
    <rect width="256" height="256" fill="none"></rect>
    <polyline
      points="64 88 16 128 64 168"
      fill="none"
      stroke="#00293b"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="24"
    ></polyline>
    <polyline
      points="192 88 240 128 192 168"
      fill="none"
      stroke="#00293b"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="24"
    ></polyline>
    <line
      x1="160"
      y1="40"
      x2="96"
      y2="216"
      fill="none"
      stroke="#00293b"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="24"
    ></line>
  </svg>

  <!-- code regular -->
  <svg
    v-else-if="props.tag === 'computing'"
    xmlns="http://www.w3.org/2000/svg"
    :class="props.size === 5 ? `w-5 h-5` : 'w-8 h-8'"
    fill="#00293b"
    viewBox="0 0 256 256"
  >
    <rect width="256" height="256" fill="none"></rect>
    <polyline
      points="64 88 16 128 64 168"
      fill="none"
      stroke="#00293b"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="16"
    ></polyline>
    <polyline
      points="192 88 240 128 192 168"
      fill="none"
      stroke="#00293b"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="16"
    ></polyline>
    <line
      x1="160"
      y1="40"
      x2="96"
      y2="216"
      fill="none"
      stroke="#00293b"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="16"
    ></line>
  </svg>

  <!-- //!#data: database -->
  <!-- database default: bold -->
  <svg
    v-else-if="props.tag === 'data' && !props.weight"
    xmlns="http://www.w3.org/2000/svg"
    :class="props.size === 5 ? `w-5 h-5` : 'w-8 h-8'"
    fill="#00293b"
    viewBox="0 0 256 256"
  >
    <rect width="256" height="256" fill="none"></rect>
    <ellipse
      cx="128"
      cy="80"
      rx="88"
      ry="48"
      fill="none"
      stroke="#00293b"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="24"
    ></ellipse>
    <path
      d="M40,80v48c0,26.50967,39.39894,48,88,48s88-21.49033,88-48V80"
      fill="none"
      stroke="#00293b"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="24"
    ></path>
    <path
      d="M40,128v48c0,26.50967,39.39894,48,88,48s88-21.49033,88-48V128"
      fill="none"
      stroke="#00293b"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="24"
    ></path>
  </svg>

  <!-- database regular -->
  <svg
    v-else-if="props.tag === 'data'"
    xmlns="http://www.w3.org/2000/svg"
    :class="props.size === 5 ? `w-5 h-5` : 'w-8 h-8'"
    fill="#00293b"
    viewBox="0 0 256 256"
  >
    <rect width="256" height="256" fill="none"></rect>
    <ellipse
      cx="128"
      cy="80"
      rx="88"
      ry="48"
      fill="none"
      stroke="#00293b"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="16"
    ></ellipse>
    <path
      d="M40,80v48c0,26.50967,39.39894,48,88,48s88-21.49033,88-48V80"
      fill="none"
      stroke="#00293b"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="16"
    ></path>
    <path
      d="M40,128v48c0,26.50967,39.39894,48,88,48s88-21.49033,88-48V128"
      fill="none"
      stroke="#00293b"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="16"
    ></path>
  </svg>

  <!-- //!#design: ruler -->
  <!-- ruler default: bold -->
  <svg
    v-else-if="props.tag === 'design' && !props.weight"
    xmlns="http://www.w3.org/2000/svg"
    :class="props.size === 5 ? `w-5 h-5` : 'w-8 h-8'"
    fill="#00293b"
    viewBox="0 0 256 256"
  >
    <rect width="256" height="256" fill="none"></rect>
    <rect
      x="26.17662"
      y="82.74517"
      width="203.64675"
      height="90.50967"
      rx="8"
      transform="translate(-53.01934 128) rotate(-45)"
      stroke-width="24"
      stroke="#00293b"
      stroke-linecap="round"
      stroke-linejoin="round"
      fill="none"
    ></rect>
    <line
      x1="132"
      y1="60"
      x2="164"
      y2="92"
      fill="none"
      stroke="#00293b"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="24"
    ></line>
    <line
      x1="96"
      y1="96"
      x2="128"
      y2="128"
      fill="none"
      stroke="#00293b"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="24"
    ></line>
    <line
      x1="60"
      y1="132"
      x2="92"
      y2="164"
      fill="none"
      stroke="#00293b"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="24"
    ></line>
  </svg>

  <!-- ruler regular -->
  <svg
    v-else-if="props.tag === 'design'"
    xmlns="http://www.w3.org/2000/svg"
    :class="props.size === 5 ? `w-5 h-5` : 'w-8 h-8'"
    fill="#00293b"
    viewBox="0 0 256 256"
  >
    <rect width="256" height="256" fill="none"></rect>
    <rect
      x="26.17662"
      y="82.74517"
      width="203.64675"
      height="90.50967"
      rx="8"
      transform="translate(-53.01934 128) rotate(-45)"
      stroke-width="16"
      stroke="#00293b"
      stroke-linecap="round"
      stroke-linejoin="round"
      fill="none"
    ></rect>
    <line
      x1="132"
      y1="60"
      x2="164"
      y2="92"
      fill="none"
      stroke="#00293b"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="16"
    ></line>
    <line
      x1="96"
      y1="96"
      x2="128"
      y2="128"
      fill="none"
      stroke="#00293b"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="16"
    ></line>
    <line
      x1="60"
      y1="132"
      x2="92"
      y2="164"
      fill="none"
      stroke="#00293b"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="16"
    ></line>
  </svg>

  <!-- //!#education: graduation cap -->
  <!-- graduation cap default: bold -->
  <svg
    v-else-if="props.tag === 'education' && !props.weight"
    xmlns="http://www.w3.org/2000/svg"
    :class="props.size === 5 ? `w-5 h-5` : 'w-8 h-8'"
    fill="#00293b"
    viewBox="0 0 256 256"
  >
    <rect width="256" height="256" fill="none"></rect>
    <polygon
      points="12 96 128 32 244 96 128 160 12 96"
      fill="none"
      stroke="#00293b"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="24"
    ></polygon>
    <polyline
      points="186 240 186 128 128 96"
      fill="none"
      stroke="#00293b"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="24"
    ></polyline>
    <path
      d="M224,107.03448v58.15691a7.956,7.956,0,0,1-1.86924,5.13972C214.4464,179.42273,183.06948,212,128,212s-86.4464-32.57727-94.13076-41.66889A7.956,7.956,0,0,1,32,165.19139V107.03448"
      fill="none"
      stroke="#00293b"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="24"
    ></path>
  </svg>

  <!-- graduation cap regular -->
  <svg
    v-else-if="props.tag === 'education'"
    xmlns="http://www.w3.org/2000/svg"
    :class="props.size === 5 ? `w-5 h-5` : 'w-8 h-8'"
    fill="#00293b"
    viewBox="0 0 256 256"
  >
    <rect width="256" height="256" fill="none"></rect>
    <polygon
      points="8 96 128 32 248 96 128 160 8 96"
      fill="none"
      stroke="#00293b"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="16"
    ></polygon>
    <polyline
      points="188 240 188 128 128 96"
      fill="none"
      stroke="#00293b"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="16"
    ></polyline>
    <path
      d="M220,110.93333v54.5208a7.95694,7.95694,0,0,1-1.58951,4.78692C211.67382,179.20536,183.2529,212,128,212s-83.67382-32.79464-90.41049-41.75895A7.95694,7.95694,0,0,1,36,165.45413v-54.5208"
      fill="none"
      stroke="#00293b"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="16"
    ></path>
  </svg>

  <!-- //!#engineering: gear -->
  <!-- gear default: fill -->
  <svg
    v-else-if="props.tag === 'engineering' && !props.weight"
    xmlns="http://www.w3.org/2000/svg"
    :class="props.size === 5 ? `w-5 h-5` : 'w-6 h-6'"
    width="192"
    height="192"
    fill="#00293b"
    viewBox="0 0 256 256"
  >
    <rect width="256" height="256" fill="none"></rect>
    <path
      d="M234.80957,150.40576l-14.87793-19.835c.02246-1.75244.0044-3.62207-.02148-5.11377l14.89257-19.856a8.0008,8.0008,0,0,0,1.32422-6.88476,111.46438,111.46438,0,0,0-10.95068-26.46094,7.99971,7.99971,0,0,0-5.80615-3.93457l-24.48731-3.49707q-1.7981-1.90282-3.70312-3.70361l-3.4961-24.47657a8.00016,8.00016,0,0,0-3.93261-5.80419,111.45413,111.45413,0,0,0-26.45313-10.96729,8.00181,8.00181,0,0,0-6.88818,1.32275L130.62207,36.0376c-1.74414-.04834-3.49316-.04834-5.2373,0l-19.7793-14.83545a8.003,8.003,0,0,0-6.88526-1.32373A111.45813,111.45813,0,0,0,72.25928,30.82861a8.00164,8.00164,0,0,0-3.93506,5.80616L64.82764,61.12158q-1.90211,1.79809-3.70362,3.7041L36.64746,68.32129a7.99959,7.99959,0,0,0-5.8042,3.93213A111.45433,111.45433,0,0,0,19.876,98.70752a7.99838,7.99838,0,0,0,1.32324,6.88769l14.87793,19.835c-.02246,1.75244-.0044,3.62207.02148,5.11377l-14.89258,19.856a8.00086,8.00086,0,0,0-1.32421,6.88477,111.46455,111.46455,0,0,0,10.95068,26.46094,7.99971,7.99971,0,0,0,5.80615,3.93457L61.126,191.17725q1.7981,1.90283,3.70312,3.70361l3.4961,24.47656a8.00016,8.00016,0,0,0,3.93261,5.8042,111.45413,111.45413,0,0,0,26.45313,10.96729,7.9997,7.9997,0,0,0,6.88818-1.32276l19.7876-14.84277c1.74414.04932,3.49316.04932,5.2373,0l19.7793,14.83545a7.99882,7.99882,0,0,0,6.88525,1.32373,111.45806,111.45806,0,0,0,26.46094-10.9502,8.00161,8.00161,0,0,0,3.93506-5.80615l3.50488-24.54541c1.25489-1.22363,2.56348-2.5581,3.60059-3.63135l24.57129-3.50976a7.99959,7.99959,0,0,0,5.8042-3.93213,111.45433,111.45433,0,0,0,10.96728-26.4541A7.9984,7.9984,0,0,0,234.80957,150.40576ZM128.00391,172a44,44,0,1,1,44-44A44.04978,44.04978,0,0,1,128.00391,172Z"
    ></path>
  </svg>

  <!-- gear regular -->
  <svg
    v-else-if="props.tag === 'engineering'"
    xmlns="http://www.w3.org/2000/svg"
    :class="props.size === 5 ? `w-5 h-5` : 'w-6 h-6'"
    width="192"
    height="192"
    fill="#00293b"
    viewBox="0 0 256 256"
  >
    <rect width="256" height="256" fill="none"></rect>
    <circle
      cx="128"
      cy="128"
      r="48"
      fill="none"
      stroke="#00293b"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="16"
    ></circle>
    <path
      d="M183.66087,65.08649a84.52233,84.52233,0,0,1,7.2522,7.2521l27.32234,3.90156A103.36981,103.36981,0,0,1,228.39912,100.801L211.84465,122.8728s.20824,6.83942.00065,10.2547l16.561,22.07863a103.36931,103.36931,0,0,1-10.18033,24.554l-27.31288,3.90133s-4.689,4.98345-7.25071,7.25163l-3.90155,27.32234A103.36971,103.36971,0,0,1,155.2,228.39912l-22.07039-16.55388a84.521,84.521,0,0,1-10.25609.00006l-22.07863,16.561a103.36914,103.36914,0,0,1-24.554-10.18033l-3.90076-27.31148a84.521,84.521,0,0,1-7.2522-7.25211l-27.32235-3.90155A103.37009,103.37009,0,0,1,27.60186,155.2l16.55447-22.07178s-.20824-6.83942-.00066-10.2547l-16.561-22.07863A103.37,103.37,0,0,1,37.775,76.24087l27.31149-3.90076a84.52233,84.52233,0,0,1,7.2521-7.2522l3.90156-27.32235A103.37019,103.37019,0,0,1,100.801,27.60186l22.0704,16.55388a84.523,84.523,0,0,1,10.25609-.00007l22.07863-16.561a103.37014,103.37014,0,0,1,24.554,10.18033Z"
      fill="none"
      stroke="#00293b"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="16"
    ></path>
  </svg>

  <!-- //!#environment: globe-hemisphere-west -->
  <!-- globe-hemisphere-west default: bold -->
  <svg
    v-else-if="props.tag === 'environment' && !props.weight"
    xmlns="http://www.w3.org/2000/svg"
    :class="props.size === 5 ? `w-5 h-5` : 'w-8 h-8'"
    fill="#00293b"
    viewBox="0 0 256 256"
  >
    <rect width="256" height="256" fill="none"></rect>
    <circle
      cx="128"
      cy="128"
      r="96"
      fill="none"
      stroke="#00293b"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="24"
    ></circle>
    <path
      d="M214.16458,170.37222l-54.28632-33.38846a7.99993,7.99993,0,0,0-3.12236-1.114l-22.81867-3.07595a8,8,0,0,0-8.37592,4.67178l-13.69878,30.73831a8,8,0,0,0,1.433,8.68736l18.79995,20.33464a8,8,0,0,1,1.98022,6.9502l-3.8294,19.79638"
      fill="none"
      stroke="#00293b"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="24"
    ></path>
    <path
      d="M64.95216,55.60635,56.00794,76.75607a8,8,0,0,0-.12321,5.92293l11.49086,30.66819a8,8,0,0,0,5.81037,5.01448l21.42993,4.60592a8,8,0,0,1,5.5197,4.3358l3.80564,7.862a8,8,0,0,0,7.20075,4.51442h13.43256"
      fill="none"
      stroke="#00293b"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="24"
    ></path>
    <path
      d="M152.45823,35.14852l9.33366,16.7184a8,8,0,0,1-1.6191,9.83314L133.26192,86.0376a8.00033,8.00033,0,0,1-1.49868,1.06968l-12.24176,6.76034a8,8,0,0,1-3.86737.9969H94.30873a8,8,0,0,0-7.37259,4.89437l-8.32721,19.76833"
      fill="none"
      stroke="#00293b"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="24"
    ></path>
  </svg>

  <!-- globe-hemisphere-west regular -->
  <svg
    v-else-if="props.tag === 'environment'"
    xmlns="http://www.w3.org/2000/svg"
    :class="props.size === 5 ? `w-5 h-5` : 'w-8 h-8'"
    fill="#00293b"
    viewBox="0 0 256 256"
  >
    <rect width="256" height="256" fill="none"></rect>
    <circle
      cx="128"
      cy="128"
      r="96"
      fill="none"
      stroke="#00293b"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="16"
    ></circle>
    <path
      d="M214.16458,170.37222l-54.28632-33.38846a7.99993,7.99993,0,0,0-3.12236-1.114l-22.81867-3.07595a8,8,0,0,0-8.37592,4.67178l-13.69878,30.73831a8,8,0,0,0,1.433,8.68736l18.79995,20.33464a8,8,0,0,1,1.98022,6.9502l-3.8294,19.79638"
      fill="none"
      stroke="#00293b"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="16"
    ></path>
    <path
      d="M64.95216,55.60635,56.00794,76.75607a8,8,0,0,0-.12321,5.92293l11.49086,30.66819a8,8,0,0,0,5.81037,5.01448l21.42993,4.60592a8,8,0,0,1,5.5197,4.3358l3.80564,7.862a8,8,0,0,0,7.20075,4.51442h13.43256"
      fill="none"
      stroke="#00293b"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="16"
    ></path>
    <path
      d="M152.45823,35.14852l9.33366,16.7184a8,8,0,0,1-1.6191,9.83314L133.26192,86.0376a8.00033,8.00033,0,0,1-1.49868,1.06968l-12.24176,6.76034a8,8,0,0,1-3.86737.9969H94.30873a8,8,0,0,0-7.37259,4.89437l-8.32721,19.76833"
      fill="none"
      stroke="#00293b"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="16"
    ></path>
  </svg>

  <!-- //!#healthcare: heartbeat -->
  <!-- heartbeat default: bold -->
  <svg
    v-else-if="props.tag === 'healthcare' && !props.weight"
    xmlns="http://www.w3.org/2000/svg"
    :class="props.size === 5 ? `w-5 h-5` : 'w-8 h-8'"
    fill="#00293b"
    viewBox="0 0 256 256"
  >
    <rect width="256" height="256" fill="none"></rect>
    <polyline
      points="32 128 72 128 88 104 120 152 136 128 160 128"
      fill="none"
      stroke="#00293b"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="24"
    ></polyline>
    <path
      d="M28.13612,88.20841a52.01064,52.01064,0,0,1,99.864-16.24342l-.00019.00008A52.00881,52.00881,0,0,1,228,92c0,68-100,124-100,124s-33.69291-18.868-61.96423-47.99889"
      fill="none"
      stroke="#00293b"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="24"
    ></path>
  </svg>

  <!-- heartbeat regular -->
  <svg
    v-else-if="props.tag === 'healthcare'"
    xmlns="http://www.w3.org/2000/svg"
    :class="props.size === 5 ? `w-5 h-5` : 'w-8 h-8'"
    fill="#00293b"
    viewBox="0 0 256 256"
  >
    <rect width="256" height="256" fill="none"></rect>
    <polyline
      points="32 128 72 128 88 104 120 152 136 128 160 128"
      fill="none"
      stroke="#00293b"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="16"
    ></polyline>
    <path
      d="M28.11427,95.99348Q28.00077,94.004,28,92A52.00881,52.00881,0,0,1,128.00008,71.965l-.00019.00008A52.00881,52.00881,0,0,1,228,92c0,68-100,124-100,124s-39.94466-22.369-69.34251-55.99891"
      fill="none"
      stroke="#00293b"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="16"
    ></path>
  </svg>

  <!-- //!#humanities: users-three -->
  <!-- users-three default: fill -->
  <svg
    v-else-if="props.tag === 'humanities' && !props.weight"
    xmlns="http://www.w3.org/2000/svg"
    :class="props.size === 5 ? `w-5 h-5` : 'w-6 h-6'"
    fill="#00293b"
    viewBox="0 0 256 256"
  >
    <rect width="256" height="256" fill="none"></rect>
    <path
      d="M63.99805,140.002a7.99955,7.99955,0,0,1-8,8h-.00049l-44.00147-.0039a8,8,0,0,1-6.3955-12.80469A67.81463,67.81463,0,0,1,33.02783,113.5127,39.99241,39.99241,0,1,1,99.29492,76.50293a7.99971,7.99971,0,0,1-3.78515,8.37695,64.36027,64.36027,0,0,0-27.85889,33.7959A63.645,63.645,0,0,0,63.99805,140.002Zm186.39941-4.81054a67.81009,67.81009,0,0,0-27.42676-21.68067A39.99246,39.99246,0,1,0,156.70361,76.5a8.00092,8.00092,0,0,0,3.78467,8.37695,64.367,64.367,0,0,1,27.85938,33.79688A63.64448,63.64448,0,0,1,192,140a8.00039,8.00039,0,0,0,8.001,8l44.001-.00391a8,8,0,0,0,6.39551-12.80468ZM157.16162,178.0896a48,48,0,1,0-58.32324,0,71.66776,71.66776,0,0,0-35.59522,34.40454A7.9997,7.9997,0,0,0,70.43457,223.999H185.56543a8.00017,8.00017,0,0,0,7.19141-11.50488A71.66776,71.66776,0,0,0,157.16162,178.0896Z"
    ></path>
  </svg>

  <!-- users-three regular -->
  <svg
    v-else-if="props.tag === 'humanities'"
    xmlns="http://www.w3.org/2000/svg"
    :class="props.size === 5 ? `w-5 h-5` : 'w-6 h-6'"
    fill="#00293b"
    viewBox="0 0 256 256"
  >
    <rect width="256" height="256" fill="none"></rect>
    <circle
      cx="128"
      cy="140"
      r="40"
      fill="none"
      stroke="#00293b"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="16"
    ></circle>
    <path
      d="M196,116a59.909,59.909,0,0,1,48.00114,23.99567"
      fill="none"
      stroke="#00293b"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="16"
    ></path>
    <path
      d="M11.99717,139.99792A59.909,59.909,0,0,1,60,116"
      fill="none"
      stroke="#00293b"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="16"
    ></path>
    <path
      d="M70.4343,215.9991a64.02584,64.02584,0,0,1,115.13125-.00031"
      fill="none"
      stroke="#00293b"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="16"
    ></path>
    <path
      d="M60,116A32,32,0,1,1,91.437,77.99243"
      fill="none"
      stroke="#00293b"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="16"
    ></path>
    <path
      d="M164.56291,77.993A32.0027,32.0027,0,1,1,196,116"
      fill="none"
      stroke="#00293b"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="16"
    ></path>
  </svg>

  <!-- //!#law: scales -->
  <!-- scales default: fill -->
  <svg
    v-else-if="props.tag === 'law' && !props.weight"
    xmlns="http://www.w3.org/2000/svg"
    :class="props.size === 5 ? `w-5 h-5` : 'w-6 h-6'"
    fill="#00293b"
    viewBox="0 0 256 256"
  >
    <rect width="256" height="256" fill="none"></rect>
    <path
      d="M239.42773,133.0293l-32-80c-.0122-.03076-.03076-.05762-.04345-.08838a7.94631,7.94631,0,0,0-.481-.98145c-.01465-.02441-.03125-.04687-.0459-.07129a7.93042,7.93042,0,0,0-.5708-.82275c-.04443-.05615-.08691-.11328-.13281-.168a7.96437,7.96437,0,0,0-.71143-.75635c-.0459-.04248-.09472-.08007-.1416-.12158a8.00092,8.00092,0,0,0-.69189-.54639c-.06787-.04785-.13428-.09765-.20362-.144a8.16785,8.16785,0,0,0-.87256-.50146c-.06787-.03321-.13769-.06153-.20654-.09278a7.92639,7.92639,0,0,0-.82617-.32519c-.06494-.02149-.12842-.0459-.19434-.06592a8.04279,8.04279,0,0,0-1.0249-.23584c-.06445-.01025-.12939-.0166-.19385-.02588a7.97774,7.97774,0,0,0-.99365-.07519C200.062,48.00684,200.03174,48,200,48c-.01221,0-.02393.00293-.03613.00293a7.87125,7.87125,0,0,0-1.07032.081c-.08789.01221-.17382.02881-.26074.044-.123.02148-.24511.03516-.36816.0625L136,62.02686V40a8,8,0,0,0-16,0V65.58252L54.26465,80.19043c-.16553.03662-.32373.0874-.48438.13379-.06933.02-.14013.03564-.209.05762a7.95224,7.95224,0,0,0-.97656.38378c-.05762.02735-.1128.05762-.16944.08643a7.92974,7.92974,0,0,0-.81787.47022c-.04394.0288-.08935.05517-.13281.085a7.96988,7.96988,0,0,0-.82764.65381c-.04834.04345-.09326.08984-.14062.13476a8.08693,8.08693,0,0,0-.63086.66992c-.04053.04834-.0835.09375-.123.14356a8.00617,8.00617,0,0,0-.58935.84961c-.041.06787-.07862.1372-.11768.20605a8.00524,8.00524,0,0,0-.45752.93262c-.00439.01123-.01123.02051-.01562.03174l-.00977.0249-.01416.03467L16.57227,165.0293A7.99723,7.99723,0,0,0,16,168c0,23.3125,24.53516,32,40,32s40-8.6875,40-32a7.99723,7.99723,0,0,0-.57227-2.9707L66.92334,93.76758,120,81.97314V208H104a8,8,0,0,0,0,16h48a8,8,0,0,0,0-16H136V78.41748l50.94531-11.32129-26.373,65.93311A7.99723,7.99723,0,0,0,160,136c0,23.3125,24.53516,32,40,32s40-8.6875,40-32A7.99723,7.99723,0,0,0,239.42773,133.0293ZM32.61621,168,56,109.541,79.38379,168Zm144-32L200,77.541,223.38379,136Z"
    ></path>
  </svg>

  <!-- scales regular -->
  <svg
    v-else-if="props.tag === 'law'"
    xmlns="http://www.w3.org/2000/svg"
    :class="props.size === 5 ? `w-5 h-5` : 'w-6 h-6'"
    fill="#00293b"
    viewBox="0 0 256 256"
  >
    <rect width="256" height="256" fill="none"></rect>
    <line
      x1="128"
      y1="40"
      x2="128"
      y2="216"
      fill="none"
      stroke="#00293b"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="16"
    ></line>
    <line
      x1="104"
      y1="216"
      x2="152"
      y2="216"
      fill="none"
      stroke="#00293b"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="16"
    ></line>
    <line
      x1="56"
      y1="88"
      x2="200"
      y2="56"
      fill="none"
      stroke="#00293b"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="16"
    ></line>
    <path
      d="M24,168c0,17.67311,20,24,32,24s32-6.32689,32-24L56,88Z"
      fill="none"
      stroke="#00293b"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="16"
    ></path>
    <path
      d="M168,136c0,17.67311,20,24,32,24s32-6.32689,32-24L200,56Z"
      fill="none"
      stroke="#00293b"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="16"
    ></path>
  </svg>

  <!-- //!#music: music-notes -->
  <!-- music-notes default: fill -->
  <svg
    v-else-if="props.tag === 'music' && !props.weight"
    xmlns="http://www.w3.org/2000/svg"
    :class="props.size === 5 ? `w-5 h-5` : 'w-6 h-6'"
    fill="#00293b"
    viewBox="0 0 256 256"
  >
    <rect width="256" height="256" fill="none"></rect>
    <path
      d="M210.29883,56.3374l-80-24A7.99991,7.99991,0,0,0,120,40V87.82031q-.0044.1831,0,.36573v60.07519A47.97353,47.97353,0,1,0,136,184V98.75195l69.70117,20.91065A7.99991,7.99991,0,0,0,216,112V64A7.99972,7.99972,0,0,0,210.29883,56.3374Z"
    ></path>
  </svg>

  <!-- music-notes regular -->
  <svg
    v-else-if="props.tag === 'music'"
    xmlns="http://www.w3.org/2000/svg"
    :class="props.size === 5 ? `w-5 h-5` : 'w-6 h-6'"
    fill="#00293b"
    viewBox="0 0 256 256"
  >
    <rect width="256" height="256" fill="none"></rect>
    <circle
      cx="88"
      cy="184"
      r="40"
      fill="none"
      stroke="#00293b"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="16"
    ></circle>
    <polyline
      points="128 184 128 40 208 64 208 112 128 88"
      fill="none"
      stroke="#00293b"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="16"
    ></polyline>
  </svg>

  <!-- //!#science: atom -->
  <!-- atom default: bold -->
  <svg
    v-else-if="props.tag === 'science' && !props.weight"
    xmlns="http://www.w3.org/2000/svg"
    :class="props.size === 5 ? `w-5 h-5` : 'w-8 h-8'"
    fill="#00293b"
    viewBox="0 0 256 256"
  >
    <rect width="256" height="256" fill="none"></rect>
    <ellipse
      cx="128"
      cy="128"
      rx="44"
      ry="116"
      transform="translate(-53.01934 128) rotate(-45)"
      stroke-width="24"
      stroke="#00293b"
      stroke-linecap="round"
      stroke-linejoin="round"
      fill="none"
    ></ellipse>
    <ellipse
      cx="128"
      cy="128"
      rx="116"
      ry="44"
      transform="translate(-53.01934 128) rotate(-45)"
      stroke-width="24"
      stroke="#00293b"
      stroke-linecap="round"
      stroke-linejoin="round"
      fill="none"
    ></ellipse>
    <circle cx="128" cy="128" r="16"></circle>
  </svg>

  <!-- atom regular -->
  <svg
    v-else-if="props.tag === 'science'"
    xmlns="http://www.w3.org/2000/svg"
    :class="props.size === 5 ? `w-5 h-5` : 'w-8 h-8'"
    fill="#00293b"
    viewBox="0 0 256 256"
  >
    <rect width="256" height="256" fill="none"></rect>
    <ellipse
      cx="128"
      cy="128"
      rx="44"
      ry="116"
      transform="translate(-53.01934 128) rotate(-45)"
      stroke-width="24"
      stroke="#00293b"
      stroke-linecap="round"
      stroke-linejoin="round"
      fill="none"
    ></ellipse>
    <ellipse
      cx="128"
      cy="128"
      rx="116"
      ry="44"
      transform="translate(-53.01934 128) rotate(-45)"
      stroke-width="24"
      stroke="#00293b"
      stroke-linecap="round"
      stroke-linejoin="round"
      fill="none"
    ></ellipse>
    <circle cx="128" cy="128" r="16"></circle>
  </svg>

  <!-- //!#social-science: globe -->
  <!-- globe default: bold -->
  <svg
    v-else-if="props.tag === 'social-science' && !props.weight"
    xmlns="http://www.w3.org/2000/svg"
    :class="props.size === 5 ? `w-5 h-5` : 'w-8 h-8'"
    fill="#00293b"
    viewBox="0 0 256 256"
  >
    <rect width="256" height="256" fill="none"></rect>
    <circle
      cx="128"
      cy="128"
      r="96"
      fill="none"
      stroke="#00293b"
      stroke-width="24"
      stroke-linecap="round"
      stroke-linejoin="round"
    ></circle>
    <line
      x1="37.46679"
      y1="96"
      x2="218.53174"
      y2="96"
      fill="none"
      stroke="#00293b"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="24"
    ></line>
    <line
      x1="37.47057"
      y1="160"
      x2="218.53448"
      y2="160"
      fill="none"
      stroke="#00293b"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="24"
    ></line>
    <ellipse
      cx="128"
      cy="128"
      rx="40"
      ry="93.42294"
      fill="none"
      stroke="#00293b"
      stroke-width="24"
      stroke-linecap="round"
      stroke-linejoin="round"
    ></ellipse>
  </svg>

  <!-- globe regular -->
  <svg
    v-else-if="props.tag === 'social-science'"
    xmlns="http://www.w3.org/2000/svg"
    :class="props.size === 5 ? `w-5 h-5` : 'w-8 h-8'"
    fill="#00293b"
    viewBox="0 0 256 256"
  >
    <rect width="256" height="256" fill="none"></rect>
    <circle
      cx="128"
      cy="128"
      r="96"
      fill="none"
      stroke="#00293b"
      stroke-width="16"
      stroke-miterlimit="10"
    ></circle>
    <line
      x1="37.46679"
      y1="96"
      x2="218.53174"
      y2="96"
      fill="none"
      stroke="#00293b"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="16"
    ></line>
    <line
      x1="37.47057"
      y1="160"
      x2="218.53448"
      y2="160"
      fill="none"
      stroke="#00293b"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="16"
    ></line>
    <ellipse
      cx="128"
      cy="128"
      rx="40"
      ry="93.42294"
      fill="none"
      stroke="#00293b"
      stroke-width="16"
      stroke-miterlimit="10"
    ></ellipse>
  </svg>

  <!-- //!#technology: cpu -->
  <!-- cpu default: bold -->
  <svg
    xmlns="http://www.w3.org/2000/svg"
    v-else-if="props.tag === 'technology' && !props.weight"
    :class="props.size === 5 ? `w-5 h-5` : 'w-8 h-8'"
    fill="#00293b"
    viewBox="0 0 256 256"
  >
    <rect width="256" height="256" fill="none"></rect>
    <rect
      x="100"
      y="100"
      width="56"
      height="56"
      stroke-width="24"
      stroke="#00293b"
      stroke-linecap="round"
      stroke-linejoin="round"
      fill="none"
    ></rect>
    <rect
      x="48"
      y="48"
      width="160"
      height="160"
      rx="8"
      stroke-width="24"
      stroke="#00293b"
      stroke-linecap="round"
      stroke-linejoin="round"
      fill="none"
    ></rect>
    <line
      x1="208"
      y1="104"
      x2="232"
      y2="104"
      fill="none"
      stroke="#00293b"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="24"
    ></line>
    <line
      x1="208"
      y1="152"
      x2="232"
      y2="152"
      fill="none"
      stroke="#00293b"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="24"
    ></line>
    <line
      x1="24"
      y1="104"
      x2="48"
      y2="104"
      fill="none"
      stroke="#00293b"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="24"
    ></line>
    <line
      x1="24"
      y1="152"
      x2="48"
      y2="152"
      fill="none"
      stroke="#00293b"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="24"
    ></line>
    <line
      x1="152"
      y1="208"
      x2="152"
      y2="232"
      fill="none"
      stroke="#00293b"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="24"
    ></line>
    <line
      x1="104"
      y1="208"
      x2="104"
      y2="232"
      fill="none"
      stroke="#00293b"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="24"
    ></line>
    <line
      x1="152"
      y1="24"
      x2="152"
      y2="48"
      fill="none"
      stroke="#00293b"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="24"
    ></line>
    <line
      x1="104"
      y1="24"
      x2="104"
      y2="48"
      fill="none"
      stroke="#00293b"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="24"
    ></line>
  </svg>

  <!-- cpu regular -->
  <svg
    xmlns="http://www.w3.org/2000/svg"
    v-else
    :class="props.size === 5 ? `w-5 h-5` : 'w-8 h-8'"
    fill="#00293b"
    viewBox="0 0 256 256"
  >
    <rect width="256" height="256" fill="none"></rect>
    <rect
      x="100"
      y="100"
      width="56"
      height="56"
      stroke-width="16"
      stroke="#00293b"
      stroke-linecap="round"
      stroke-linejoin="round"
      fill="none"
    ></rect>
    <rect
      x="48"
      y="48"
      width="160"
      height="160"
      rx="8"
      stroke-width="16"
      stroke="#00293b"
      stroke-linecap="round"
      stroke-linejoin="round"
      fill="none"
    ></rect>
    <line
      x1="208"
      y1="104"
      x2="232"
      y2="104"
      fill="none"
      stroke="#00293b"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="16"
    ></line>
    <line
      x1="208"
      y1="152"
      x2="232"
      y2="152"
      fill="none"
      stroke="#00293b"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="16"
    ></line>
    <line
      x1="24"
      y1="104"
      x2="48"
      y2="104"
      fill="none"
      stroke="#00293b"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="16"
    ></line>
    <line
      x1="24"
      y1="152"
      x2="48"
      y2="152"
      fill="none"
      stroke="#00293b"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="16"
    ></line>
    <line
      x1="152"
      y1="208"
      x2="152"
      y2="232"
      fill="none"
      stroke="#00293b"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="16"
    ></line>
    <line
      x1="104"
      y1="208"
      x2="104"
      y2="232"
      fill="none"
      stroke="#00293b"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="16"
    ></line>
    <line
      x1="152"
      y1="24"
      x2="152"
      y2="48"
      fill="none"
      stroke="#00293b"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="16"
    ></line>
    <line
      x1="104"
      y1="24"
      x2="104"
      y2="48"
      fill="none"
      stroke="#00293b"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="16"
    ></line>
  </svg>
</template>

<script setup>
const props = defineProps({
  tag: {
    type: String,
    required: true,
  },
  size: {
    type: Number,
  },
  weight: {
    type: String,
  },
});
</script>
